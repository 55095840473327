import * as React from 'react';

import VideoContainer from '../components/page-components/videoEmbedContainer';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Divider from '../components/sections/sectionDivider';
import HeadingSection from '../components/sections/headingSection';
import PageIntro from '../components/sections/pageIntro';
import ImageSection from '../components/sections/imageSection';

const LearnGuitar = () => (
  <Layout>
    <SEO title="Learn Guitar" />
    <HeadingSection heading="Learn Guitar" />
    <Divider />
    <PageIntro
      text="Since you’ve made it this far you must be serious about learning guitar. My learning pathway is designed to help you make real progress, no matter what your level is, or what style it is you want to play. Why not book a lesson now, and see you I can help you reach your guitar goals. Let’s get to it!"
      link="Check out my channel for some FREE tips to get you started"
      to="https://www.youtube.com/channel/UCEqRE9a-ZjROzYYCTOsQhdw"
    />
    <Divider />
    <ImageSection
      textColor="text-white"
      backgroundColor="bg-primary"
      heading="Struggling with open chords?"
      text="Try these 3 tips to help you make progress right away."
    >
      <VideoContainer>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/SdAYwNls2YU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>
    </ImageSection>
    <Divider />
    <ImageSection
      reverse
      textColor="text-white"
      backgroundColor="bg-primary"
      heading="Mixolyian Funk Lick"
      text="How to take an idea across the fretboard."
    >
      <VideoContainer>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/DVccjOfGw3s"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>
    </ImageSection>
  </Layout>
);

export default LearnGuitar;
